<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <vField
                            v-model="data.product_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <AsyncSelect
                          placeholder="Select Product"
                          v-model="selectedProduct"
                          :api-service="fetchProductList"
                          :format-label="option => option.text"
                          :additional-option="additionalOptions"
                          label="text"
                        />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                </div>

                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Rate</label>
                            <vField
                                v-model="data.rate"
                                name="rate"
                                type="number"
                                class="form-control text-right"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">Quantity</label>
                            <vField
                                v-model="data.quantity"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                :readonly="disableQtyField"
                            />
                        </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>
                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-percent">Discount Percent</label>
                        <input
                          v-model.trim="data.discount_percent"
                          type="number" class="form-control"
                          placeholder="Discount(%)"
                          :min="0"
                          id="discount-percent"
                        >
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount_amount">Discount Amount</label>
                        <vField
                          v-model="data.discount_amount"
                          name="discount-amount"
                          type="number"
                          class="form-control text-right"
                          min="0"
                          id="discount_amount"
                        />
                    </div>
                </div>
                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount_amount">After Discount Amount</label>
                        <vField
                          v-model="amountAfterDiscount"
                          name="amount"
                          type="number"
                          class="form-control text-right"
                          disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT Rate</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select text-right"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">Vat amount</label>
                        <vField
                            v-model="data.vat_amount"
                            name="vat_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                        />
                    </div>
                </div>

                <slot v-if="isItem">
                  <div class="col-md-2 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="batch_number">Batch Number</label>
                      <vField
                        v-model="data.batch_number"
                        name="batch_number"
                        type="text"
                        id="batch_number"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="mfn_date">Manufacturing Date</label>
                      <vField
                        v-model="data.mfn_date"
                        name="mfn_date"
                        id="mfn_date"
                        type="month"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-md-3 col-12">
                    <div class="mb-1">
                      <label class="form-label" for="exp_date">Expiry Date</label>
                      <vField
                        v-model="data.exp_date"
                        name="exp_date"
                        id="exp_date"
                        type="month"
                        class="form-control"
                      />
                    </div>
                  </div>
                </slot>

                <div class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                         <vField
                            style="height: 38px"
                            as="textarea"
                            v-model="data.description"
                            class="form-control"
                            name="description"
                        />
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "@vue/runtime-core"
import {onMounted, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const {fetchProductList} = handlePurchaseAndSales()
const route = useRoute();
const vatAmount = ref(0);
const selectedProduct = ref(null);

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  index: Number,
  vatRate: Array,
  accountHeads: Array
})

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.rate * pr.data.quantity
    }
    if(!pr.isItem) {
        return pr.data.amount
    }
})

const vatPercent = computed(() => pr.data.vat);

const vatAmountInput = computed(() => pr.data.vat_amount)

const amountAfterDiscount = computed(() => {
  const discountAmount = pr.data.discount_amount === '' ? 0 : parseFloat(pr.data.discount_amount)
  if(!discountAmount || discountAmount === 0) {
    return amount.value;
  }
  return amount.value - discountAmount
})

const additionalOptions = computed(() => {
  if (route.params.purchaseId && pr.data.product_id && pr.data.product) {
    return [
      {
        id: pr.data.product_id,
        text: pr.data.product.name,
      }
    ]
  }
});

const discountPercent = computed(() => pr.data.discount_percent);
const disableQtyField = computed(() => pr.data.hasOwnProperty('can_edit_qty') && !pr.data.can_edit_qty );

watch(vatPercent, () => {
  if(pr.data.vat === 0 && pr.data.vat_amount === 0) {
    vatAmount.value = 0
    return;
  }

  if(pr.data.vat && pr.isItem) {
    vatAmount.value = (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2)
    return;
  }

  vatAmount.value = (pr.data.vat/100 * pr.data.amount).toFixed(2)
})

watch(selectedProduct, (newValue) => {
    pr.data.product_id = newValue.id ?? null;
})

watch(vatAmountInput, () => {
    vatAmount.value = pr.data.vat_amount ?? 0
})

watch(vatAmount, (newValue, oldValue) => {
  if(! isNaN(oldValue) || !route.params.purchaseId){
    pr.data.vat_amount  = newValue;
  }
})

watch(discountPercent, (value) => {
  let discount = 0;
  value = parseFloat(value);
  if(value && value > 0 && amount.value > 0) {
    discount = amount.value * (value/100);
  }
  pr.data.discount_percent = value;
  pr.data.discount_amount = discount.toFixed(2);
})

const totalAmount = computed(() => {
    if(pr.data.vat === 0 && vatAmount.value === 0) {
        return parseInt(amountAfterDiscount.value)
    }
    if(pr.isItem) {
        return parseInt(amountAfterDiscount.value) + parseInt(vatAmount.value)
    }
    return parseInt(pr.data.amount) + parseInt(vatAmount.value)
})

onMounted(() => {
    if (pr.data && pr.data.product) {
        selectedProduct.value = {
            ...pr.data.product,
            text: pr.data.product.name,
        };
    }
})

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

